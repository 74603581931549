export default {
  name: 'AutoUpdateApiData',
  data () {
    return {
      autoUpdateApiDataOptions: {
        timeout  : 10 * 60 * 1000,
        autoStart: true,
        callback : 'getData',
        interval : null
      }
    }
  },
  watch  : {},
  methods: {
    startAutoUpdateApiData (cb) {
      this.stopAutoUpdateApiData()
      this.autoUpdateApiDataOptions.interval = setInterval(cb, this.autoUpdateApiDataOptions.timeout)
    },
    stopAutoUpdateApiData () {
      if (this.autoUpdateApiDataOptions.interval) {
        clearInterval(this.autoUpdateApiDataOptions.interval)
      }
    }
  },

  created () {
    if (!this.autoUpdateApiDataOptions.autoStart) return

    if (typeof this.autoUpdateApiDataOptions.callback === 'function') {
      this.startAutoUpdateApiData(this.autoUpdateApiDataOptions.callback)
    } else if (typeof this.autoUpdateApiDataOptions.callback === 'string') {
      if (typeof this[this.autoUpdateApiDataOptions.callback] === 'function') {
        this.startAutoUpdateApiData(this[this.autoUpdateApiDataOptions.callback])
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn('AutoUpdateApiData:: No valid callback function passed!')
    }
  },

  beforeDestroy () {
    this.stopAutoUpdateApiData()
  }

}
